<template>
    <div class="doc-container" >
      <div class="content">
        <div class="title">用户注册协议</div>
        <div class="article">
            <p class="article-title"><strong>总章</strong></p>
            <p class="article-title"><strong>1.【特别提示】</strong></p>
            <p>本用户注册协议（以下简称&ldquo;本协议&rdquo;）由佛山南海铝拍拍科技有限公司为规范用户在铝拍拍平台上的注册、认证流程以及用户的权利义务而制定。当用户点击&ldquo;<strong>阅读并同意&rdquo;</strong>后，本协议即构成对佛山南海铝拍拍科技有限公司及用户均有约束力的法律文件。</p>
            <p>用户应认真阅读并遵守本规则及其他任何适用的平台规则。<strong><u>用户务必审慎阅读、充分理解本协议的各项条款内容，特别是免除或者限制</u></strong><strong><u>柏</u></strong><strong><u>斯基方责任的条款、争议解决和法律适用条款。</u></strong>前述条款可能以加粗字体和加下划线显示，应重点阅读。<strong><u>除非用户已阅读并接受本协议和其他平台规则的所有条款，否则用户无权使用铝拍拍平台。用户使用铝拍拍平台即视为用户已充分理解并同意本协议和其他平台规则的约束。用户无权以未阅读或不了解本协议为由主张本协议无效，或要求撤销本协议。&nbsp;</u></strong></p>
            <p class="article-title"><strong>2.【适用范围】</strong></p>
            <p>本协议适用于用户在铝拍拍平台上的账户注册、服务使用、服务终止及账户注销。</p>
            <p class="article-title"><strong>3.【定义】</strong></p>
            <p>除本协议另有规定外，本协议中提及的术语应当与《铝拍拍平台规则总则》中定义了的术语具有相同的含义。</p>
            <p class="article-title"><strong>第一章 用户的注册</strong></p>
            <p class="article-title"><strong>第一节 注册流程</strong></p>
            <p class="article-title"><strong>4.【游客权限】</strong></p>
            <p>用户未经注册、认证程序前登录铝拍拍平台，仅可作为游客，浏览铝拍拍平台向游客开放的信息和使用向游客开放的功能，但无权限使用铝拍拍平台的任何其他功能。</p>
            <p class="article-title"><strong>5.【认证用户权限】</strong></p>
            <p>用户完成相应注册、认证程序后，即成为铝拍拍平台的认证用户（包括个人用户和企业用户）。认证用户可浏览平台向认证用户开放的信息和使用向认证用户开放的功能。经认证的个人用户与企业用户均有权浏览铝拍拍平台向用户开放的信息，但仅有企业用户有权限参与铝拍拍平台上的交易。</p>
            <p class="article-title"><strong>6.【用户认证流程】</strong></p>
            <p class="article-title"><strong>6.1&nbsp;</strong>用户以自然人身份进行注册后，即可成为铝拍拍平台的个人用户。</p>
            <p class="article-title"><strong>6.2</strong>&nbsp;用户上传企业营业执照、一般纳税人资格证书授权委托书，并填写用户基本信息（包括但不限于名称、联系地址、联系方式等）后，经铝拍拍平台审核通过，可以被认证为企业用户。<strong><u>企业用户进行认证应视为其陈述、保证和承诺该企业在作为铝拍拍平台用户的整个期间是依据中华人民共和国法律成立并有效存续的企业法人或其他组织，且具备从事铝拍拍平台相关业务的资质。</u></strong></p>
            <p class="article-title"><strong><u>6.3 上文第6.2条所述授权委托书是指企业授权特定自然人代表企业在铝拍拍平台上进行一切民事行为并授权其管理其他企业员工权限的授权文件。授权委托书需加盖企业公章，并由企业的法定代表人签字。被授权的自然人需与注册的自然人为同一人。企业提交该等授权委托书后，该被授权自然人在铝拍拍平台上所从事的一切行为均视作企业的行为，对企业均有约束力，其从事任何行为无需再获得企业的任何其他授权，且企业需要对被授权自然人在铝拍拍平台上的行为承担相应责任。此外，该等被授权人将被视为企业管理员，其有权限将企业对其的授权分授权给其他企业员工，并对所有企业员工的权限进行管理，被分授权的员工可以选择加入企业账户以获得相应权限。该等分授权不需要额外出具授权委托书。所有被分授权的企业员工在铝拍拍平台上所从事的一切行为同样均会被视作企业的行为，对企业均有约束力。</u></strong></p>
            <p class="article-title"><strong>第二节 注册时的用户义务</strong></p>
            <p class="article-title"><strong>7.【诚信义务】</strong></p>
            <p>用户提供注册资料时应履行诚信义务，确保提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。<strong><u>如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且佛山南海铝拍拍科技有限公司保留终止用户使用平台各项服务的权利。</u></strong></p>
            <p class="article-title"><strong>8.【责任承担】</strong></p>
            <p class="article-title"><strong><u>如用户提供的任何注册资料侵犯任何第三方的合法权益，用户应当自行承担责任，如由于该等注册资料侵权对铝拍拍平台造成任何损失的，用户应当向铝拍拍平台进行充分赔偿。</u></strong></p>
            <p class="article-title"><strong>9.【不得转让】</strong></p>
            <p>用户注册成功后，应当谨慎保存、使用其用户名和密码，不得将账户转让、或借给他人使用。否则，如因他人使用的行为而产生的任何法律责任，用户应承担连带责任。</p>
            <p>如果发现任何人不当使用用户的账户或有任何其他可能危及用户的账户安全的情形时，用户应当立即以有效方式通知平台，要求平台采取措施。同时，<strong><u>用户应理解佛山南海铝拍拍科技有限公司对用户的请求采取措施需要合理期限，在此之前，佛山南海铝拍拍科技有限公司对用户的损失不承担责任。</u></strong></p>
            <p class="article-title"><strong>10.【信息更新义务】</strong></p>
            <p class="article-title"><strong><u>用户公示信息发生变更的，用户应当在变更发生后的三个工作日内通过修改用户信息的方将变更情况告知佛山南海铝拍拍科技有限公司，佛山南海铝拍拍科技有限公司将对该等变更信息进行核验，并更新公示的用户信息。此外，卖家除遵守签署义务外，应当至少每六个月更新或重新提交一次其身份、地址、联系方式、行政许可等信息，以确保符合卖家的最新情况。</u></strong>在法律允许的范围内，佛山南海铝拍拍科技有限公司有权不时对用户的信息进行核实，用户应当配合该等核实，并提供最新、真实、完整、有效的信息。</p>
            <p class="article-title"><strong>第二章 平台服务使用的一般性规范</strong></p>
            <p class="article-title"><strong>第一节 用户规定</strong></p>
            <p class="article-title"><strong>11.【用户注意事项】</strong></p>
            <p class="article-title"><strong>11.1</strong>&nbsp;用户在铝拍拍平台使用铝拍拍平台服务过程中，实施的所有行为均须遵守国家法律、法规等规范性文件及平台各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不违反本协议及相关规则。如果违反前述承诺，产生任何法律后果的，用户应自行承担所有法律责任。</p>
            <p class="article-title"><strong>11.2</strong>&nbsp;用户在交易过程中，应当遵守诚实信用原则，不采取不正当竞争行为，不扰乱平台服务的正常秩序，不从事与平台服务无关的行为。</p>
            <p class="article-title"><strong>11.3&nbsp;</strong>用户须保证所有其在铝拍拍平台上架的商品信息的时效性、有效性、真实性、合法性和准确性等，以及上架商品本身的适售性、适用于特定用途性和非侵权性。</p>
            <p class="article-title"><strong>11.4</strong>&nbsp;用户理解并同意：铝拍拍平台的默认交易时间为每周一至周五的上午09:00-11:30和下午13:30-16:30，</p>
            <p>&nbsp;</p>
            <p>卖家有权根据实际需求自定义交易时间。佛山南海铝拍拍科技有限公司有权在发生系统异常等无法预见的情况时调整交易时间。交易时间若有变更，佛山南海铝拍拍科技有限公司将及时在铝拍拍平台上发布公告进行告知；</p>
            <p class="article-title"><strong>11.5&nbsp;</strong>用户理解并同意：佛山南海铝拍拍科技有限公司有权根据业务情况或者系统客观情况，新增或者更改平台各类产品或服务，变更情况将及时通过铝拍拍平台发布公告进行告知。用户应自行主动留意铝拍拍平台的公告。该等产品或服务的变更，除有另行规定，自发布公告之日起生效；</p>
            <p class="article-title"><strong>11.6</strong>&nbsp;用户理解并同意：铝拍拍平台上的商品和/或服务的价格、数量、是否有货等信息随时都有可能发生变动，佛山南海铝拍拍科技有限公司对此不作特别通知。由于网站上商品和服务信息的数量极其庞大，虽然佛山南海铝拍拍科技有限公司会尽最大努力保证用户所浏览信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，铝拍拍平台网页显示的信息可能会有一定的滞后性或差错。</p>
            <p class="article-title"><strong>12.【用户禁止行为】</strong></p>
            <p>用户在铝拍拍平台使用铝拍拍平台服务的过程中，不得从事下列行为:</p>
            <p class="article-title"><strong>12.1&nbsp;</strong>对铝拍拍平台上的任何数据作商业性利用，包括但不限于在未经佛山南海铝拍拍科技有限公司事先书面同意的情况下，以复制、传播等任何方式使用铝拍拍平台上展示的资料；</p>
            <p class="article-title"><strong>12.2</strong>&nbsp;使用任何装置、软件或例行程序干预或试图干预综合平台的正常运作或正在平台上进行的任何交易、活动；</p>
            <p class="article-title"><strong>12.3&nbsp;</strong>采取任何将导致不合理的庞大数据负载加诸综合平台网络设备的行动；</p>
            <p class="article-title"><strong>12.4</strong>&nbsp;删除铝拍拍平台及其副本上关于著作权的信息；</p>
            <p class="article-title"><strong>12.5</strong>&nbsp;对铝拍拍平台进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现网站的源代码；</p>
            <p class="article-title"><strong>12.6&nbsp;</strong>对佛山南海铝拍拍科技有限公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</p>
            <p class="article-title"><strong>12.7</strong>&nbsp;使用铝拍拍平台进行任何危害网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/账户；未经允许进入公众网络或者其他人的操作系统并删除、修改、增加存储信息；</p>
            <p class="article-title"><strong>12.8</strong>&nbsp;通过修改或伪造网站运行中的指令、数据，增加、删减、变动网站的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</p>
            <p class="article-title"><strong>12.9</strong>&nbsp;通过非佛山南海铝拍拍科技有限公司开发、授权的第三方软件、插件、外挂、系统，登录或使用本网站及服务，或制作、发布、传播上述工具；</p>
            <p class="article-title"><strong>12.10&nbsp;</strong>自行或者授权他人、第三方软件对铝拍拍平台及其组件、模块、数据进行干扰。</p>
            <p class="article-title"><strong>13.【用户授权】</strong></p>
            <p class="article-title"><strong><u>用户在铝拍拍平台使用铝拍拍平台服务的过程中，授权佛山南海铝拍拍科技有限公司从事下列行为：</u></strong></p>
            <p class="article-title"><strong><u>13.1 依据平台规则合理裁量用户是否违反了本协议，并根据该等认定适用相应平台规则予以处理或终止提供服务，且无须事先通知用户或征得用户同意；</u></strong></p>
            <p class="article-title"><strong><u>13.2 基于维护铝拍拍平台各产品与服务的秩序及交易安全的需要，在发生危及交易安全、扰乱市场正常交易秩序的行为时，执行撤销相应交易订单等操作。前述危及交易安全、扰乱市场正常交易秩序的行为包括但不限于：(i)交易中诱导交易对方违背正常交易流程操作的行为；(ii)短期内用户因多次违反合同、投诉量或纠纷量过大，经佛山南海铝拍拍科技有限公司综合判断有理由认为用户无履行合同之意图的行为；(iii)并非基于真实交易需求，使用错误交易信息（包括但不限于收货信息）、短时间内大量下单等，经佛山南海铝拍拍科技有限公司综合判断有理由认为属于恶意购买的行为；(iv)其他佛山南海铝拍拍科技有限公司认为危及交易安全、扰乱市场正常交易秩序的行为；</u></strong></p>
            <p class="article-title"><strong><u>13.3 经国家行政或司法机关的生效法律文书确认用户存在违法或侵权行为，或者佛山南海铝拍拍科技有限公司根据自身的判断，认为用户的行为涉嫌违反本协议和/或相关规则的条款或涉嫌违反法律法规的规定的，在必要的范围内，在铝拍拍平台上公示用户该等涉嫌违法或违约行为及已对用户采取的措施。</u></strong></p>
            <p class="article-title"><strong>1</strong><strong>4</strong><strong>.【反贿赂】</strong></p>
            <p class="article-title"><strong><u>用户在使用铝拍拍平台服务期间，应当遵守反贿赂相关的适用法律法规并履行反贿赂相关义务。用户不得为获取任何不正当利益或不正当优势，进行包括但不限于如下之行为：</u></strong></p>
            <p class="article-title"><strong><u>1</u></strong><strong><u>4</u></strong><strong><u>.1 向佛山南海铝拍拍科技有限公司员工和/或其关联方提供财务、消费、款待、商业机会或其他有价值之物品；</u></strong></p>
            <p class="article-title"><strong><u>1</u></strong><strong><u>4</u></strong><strong><u>.2 向佛山南海铝拍拍科技有限公司员工和/或其关联方提供贷款；</u></strong></p>
            <p class="article-title"><strong><u>1</u></strong><strong><u>4</u></strong><strong><u>.3雇佣佛山南海铝拍拍科技有限公司的任何员工。</u></strong></p>
            <p class="article-title"><strong>第二节 知识产权保护</strong></p>
            <p class="article-title"><strong>1</strong><strong>5</strong><strong>.【知识产权授权】</strong></p>
            <p class="article-title"><strong><u>用户接受本协议后，对于用户提供、上传、创作、发布在铝拍拍平台的文字、评论、图片、照片、视频、音频等任何形式的信息内容（包括但不限于评价、商品图片、各类话题文章等）的知识产权均不会发生转移。</u></strong></p>
            <p class="article-title"><strong><u>与此同时，用户授权佛山南海铝拍拍科技有限公司在法律允许的范围内使用上述信息。用户同意佛山南海铝拍拍科技有限公司及其关联方有权存储、使用、复制、修订、编辑、发布、展示、翻译、分发、推广、出版、发行、信息网络传播用户以上信息内容，有权改编制作派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。</u></strong></p>
            <p class="article-title"><strong>1</strong><strong>6</strong><strong>.【不侵权】</strong></p>
            <p class="article-title"><strong><u>用户接受本协议的同时，向佛山南海铝拍拍科技有限公司陈述、保证和承诺，其对于在铝拍拍平台上发布的各类信息内容均享有知识产权或相应的使用权。</u></strong></p>
            <p class="article-title"><strong><u>用户应确保该等发布在铝拍拍平台上的各类信息不涉及侵犯第三方肖像、隐私、知识产权或其他合法权益。否则用户理解并同意，佛山南海铝拍拍科技有限公司有权随时采取相应措施，包括但不限于：要求相关用户作出回应；删除、屏蔽、断开相关商品的链接；删除相关侵权信息；终止交易和服务；冻结相关用户账户中全部或部分资金等。</u></strong></p>
            <p class="article-title"><strong>1</strong><strong>7</strong><strong>.【知识产权归属】</strong></p>
            <p>铝拍拍平台所载的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件）以及数据资产（包括但不限于交易数据、订单数据、用户数据、网站流量数据等），所有权均属于佛山南海铝拍拍科技有限公司或其内容提供者，受相关法律的保护。铝拍拍平台上所有内容汇编是佛山南海铝拍拍科技有限公司的财产，受相关法律保护。铝拍拍平台上所有软件都是佛山南海铝拍拍科技有限公司或其软件供应商的财产，受相关法律保护。</p>
            <p class="article-title"><strong>第三节 违约责任</strong></p>
            <p class="article-title"><strong>1</strong><strong>8</strong><strong>.【违约情形】</strong></p>
            <p>用户使用铝拍拍平台服务时违反有关法律法规规定的，或用户违反本协议或其他铝拍拍平台规则任何条款的，视为用户违约。</p>
            <p class="article-title"><strong>19</strong><strong>.【佛山南海铝拍拍科技有限公司措施】</strong></p>
            <p class="article-title"><strong><u>如果佛山南海铝拍拍科技有限公司发现或收到他人举报投诉用户违反有关法律法规规定，或违反本协议或其他铝拍拍平台规则任何条款的，佛山南海铝拍拍科技有限公司有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、中止全部或部分服务、帐号冻结、IP地址封禁等处罚，并公告处理结果。</u></strong></p>
            <p class="article-title"><strong>2</strong><strong>0</strong><strong>.【责任承担】</strong></p>
            <p class="article-title"><strong><u>如因用户违反任何法律法规或本协议的任何条款，导致产生任何第三方主张的索赔要求时，用户应当独立承担责任。佛山南海铝拍拍科技有限公司方因用户的前述行为而发生的任何损害、损失、权利要求、诉讼、付款要求、判决、和解、税费、利息、费用和开支（包括但不限于合理的律师费用），用户应当进行赔偿。</u></strong></p>
            <p class="article-title"><strong>2</strong><strong>1</strong><strong>.【免责条款】</strong></p>
            <p class="article-title"><strong><u>如由于在佛山南海铝拍拍科技有限公司可控范围之外的因素，包括但不限于，黑客攻击、计算机病毒侵入或发作、系统或通讯网络故障，或其他不可抗力事件，而对用户造成的任何损失，佛山南海铝拍拍科技有限公司不承担任何法律责任。</u></strong></p>
            <p class="article-title"><strong>第三章 铝拍拍平台服务的中止或终止</strong></p>
            <p class="article-title"><strong>第一节 铝拍拍平台处罚措施</strong></p>
            <p class="article-title"><strong>2</strong><strong>2</strong><strong>.【中止全部或部分服务及账户的冻结】</strong></p>
            <p class="article-title"><strong><u>如出现下列情况，佛山南海铝拍拍科技有限公司有权中止全部或部分服务，或冻结用户的账户：</u></strong></p>
            <p class="article-title"><strong><u>2</u></strong><strong><u>2</u></strong><strong><u>.1 用户违反有关法律法规规定，或违反本协议任何条款的；</u></strong></p>
            <p class="article-title"><strong><u>2</u></strong><strong><u>2</u></strong><strong><u>.2 用户自身运营出现重大恶性变故、资金链断裂，或存在重大潜在损失，后续合作风险极大的；</u></strong></p>
            <p class="article-title"><strong><u>2</u></strong><strong><u>2</u></strong><strong><u>.3 用户采用不正当手段进行谋利行为，正在被佛山南海铝拍拍科技有限公司查证的；</u></strong></p>
            <p class="article-title"><strong><u>2</u></strong><strong><u>2</u></strong><strong><u>.4 用户注册信息中的主要内容不真实、不准确、不完整或发生变更未及时更新；</u></strong></p>
            <p class="article-title"><strong><u>2</u></strong><strong><u>2</u></strong><strong><u>.5 其他佛山南海铝拍拍科技有限公司合理认为应当中止服务，或冻结账号的行为。</u></strong></p>
            <p class="article-title"><strong>2</strong><strong>3</strong><strong>.【IP地址封禁】</strong></p>
            <p class="article-title"><strong><u>佛山南海铝拍拍科技有限公司在认定用户存在下列违约行为时，有权直接封禁用户的IP的地址：</u></strong></p>
            <p class="article-title"><strong><u>2</u></strong><strong><u>3</u></strong><strong><u>.1 佛山南海铝拍拍科技有限公司中止用户全部或部分服务，或冻结用户账户后，用户再一次直接或间接以他人名字注册为铝拍拍平台用户；</u></strong></p>
            <p class="article-title"><strong><u>2</u></strong><strong><u>3</u></strong><strong><u>.2用户采用不正当手段在铝拍拍平台进行谋利行为，无论是否获得利益。</u></strong></p>
            <p class="article-title"><strong><u>2</u></strong><strong><u>3</u></strong><strong><u>.3其它佛山南海铝拍拍科技有限公司合理认为应当终止服务的情况。</u></strong></p>
            <p class="article-title"><strong>2</strong><strong>4</strong><strong>.【主动注销】</strong></p>
            <p class="article-title"><strong><u>如用户自行决定终止在铝拍拍平台上的所有业务，应在联系铝拍拍平台客服提出账户注销申请，并提前三十日在首页显著位置持续公示终止业务的有关信息。</u></strong>佛山南海铝拍拍科技有限公司会在三十日公示期结束后，注销该用户账户，视作用户与佛山南海铝拍拍科技有限公司终止了本协议。<strong><u>如卖家决定终止在铝拍拍平台上的所有业务，除上述程序外，还应采取合理、必要、及时的措施保障买家和相关经营者的合法权益。</u></strong></p>
            <p class="article-title"><strong>第二节 服务中止或终止后的交易</strong></p>
            <p class="article-title"><strong>2</strong><strong>5</strong><strong>.【交易处理】</strong></p>
            <p class="article-title"><strong>2</strong><strong>5</strong><strong>.1&nbsp;</strong>用户在账户冻结、注销或IP地址封禁之前已经上传至铝拍拍平台的商品尚未交易的，佛山南海铝拍拍科技有限公司有权在冻结或注销用户账户的同时从铝拍拍平台上删除此项商品的相关信息；</p>
            <p class="article-title"><strong>2</strong><strong>5</strong><strong>.2&nbsp;</strong>用户在账户冻结、注销或IP地址封禁之前已经生成订单，但尚未实际履行的，用户仍有义务履行该笔订单项下的义务；</p>
            <p class="article-title"><strong>2</strong><strong>5</strong><strong>.3&nbsp;</strong>对于基于上述的删除行为所产生的任何争议、损失或费用，用户应独立承担，且应确保佛山南海铝拍拍科技有限公司免于因该等删除行为而产生任何损失或承担任何费用。</p>
            <p class="article-title"><strong>第三节 服务终止后的权利义务</strong></p>
            <p class="article-title"><strong>2</strong><strong>6</strong><strong>.【佛山南海铝拍拍科技有限公司权利】</strong></p>
            <p>在用户与佛山南海铝拍拍科技有限公司终止本协议后，佛山南海铝拍拍科技有限公司仍享有下列权利：</p>
            <p class="article-title"><strong>2</strong><strong>6</strong><strong>.1</strong>&nbsp;根据相关法律要求，继续保存用户使用铝拍拍平台服务期间的所有相关商品和服务信息、交易信息三年或更长时间；</p>
            <p class="article-title"><strong>2</strong><strong>6</strong><strong>.2&nbsp;</strong>用户在使用铝拍拍平台服务期间存在违反法律法规或违反本协议或其他铝拍拍平台规则的行为的，佛山南海铝拍拍科技有限公司仍可依据本协议向用户主张提出相应主张；</p>
            <p class="article-title"><strong>2</strong><strong>6</strong><strong>.3</strong>&nbsp;佛山南海铝拍拍科技有限公司将在法律允许的范围内，记录、保存平台上发布的商品和服务信息、并按照<u>《隐私和信息保护政策》</u>的规定，收集用户使用平台期间所产生的用户信息，佛山南海铝拍拍科技有限公司将严格按照法律法规要求及<u>《隐私和信息保护政策》</u>的规定使用前述信息。</p>
            <p class="article-title"><strong>第四章 附则</strong></p>
            <p class="article-title"><strong>2</strong><strong>7</strong><strong>.【通知】</strong></p>
            <p>佛山南海铝拍拍科技有限公司方可以通过电子邮件、手机短信、传真、APP推送、铝拍拍平台即时通讯工具的方式向用户在铝拍拍平台注册时提供的电子邮件地址、手机号码、传真号进行通知。通过电子邮件、手机短信、APP推送或铝拍拍平台即时通讯工具方式进行通知，送达时间以相关电子邮件、手机短信、推送或通讯内容在铝拍拍方系统中记载的发出时间为准；通过传真方式进行通知，送达时间为相关传真的发出时间。</p>
            <p class="article-title"><strong><u>同时，佛山南海铝拍拍科技有限公司方也有权通过铝拍拍平台以公告的方式通知用户与任何铝拍拍平台项下产品或服务有关的任何事宜，用户有义务不时关注铝拍拍平台的公告信息。公告或通知与本协议不一致的，以公告或通知的内容为准。</u></strong></p>
            <p class="article-title"><strong>2</strong><strong>8</strong><strong>.【排除不利于起草方的解释原则】</strong></p>
            <p>本协议应以字面意思进行解释。在法律法规允许的范围内，以不利于起草方的解释排除歧义的原则在解释本协议时不被采用。</p>
            <p class="article-title"><strong>29</strong><strong>.【权利可累计性；放弃】</strong></p>
            <p>本协议项下双方所享有的权利是可累计的并且所涉主体均可按照其认为适当的频率行使其权利。除非明示放弃或书面变更，本协议项下任一方所享有的权利不可被放弃或者改变。针对前述权利的任何不行使或者延迟行使均不应被视为对该权利或其他权利的放弃或者变更。针对前述权利的任何瑕疵行使和部分行使均不应排除对该等权利或其他权利的进一步或者其他行使。任何一方的任何作为、行为或者谈判均不得以任何方式阻碍该方行使任何此类权利或构成任何此类权利的中止或变更。</p>
            <p class="article-title"><strong>3</strong><strong>0</strong><strong>.【可分割性】</strong></p>
            <p>如果本协议项下任何一项或者多项条款因任何原因被认定为无效或不可执行，该一项或多项条款应被视为与本协议项下的其他规定内容相分割，并且前述无效或不可执行的条款在任何情况下均不应对本协议项下其他条款的效力和执行力产生影响，也不得影响本规则所涉任何一方所享有的权利。在法律允许的范围内，本协议所涉任何一方特此放弃适用任何使得本规则任何规定在任何方面无效或不可执行的任何法律规定的权利。</p>
            <p class="article-title"><strong>3</strong><strong>1</strong><strong>.【法律适用及争议解决】</strong></p>
            <p class="article-title"><strong><u>本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国法律（不包括其法律适用法）。</u></strong></p>
            <p>用户和佛山南海铝拍拍科技有限公司方因本协议产生的，或与本规则相关的任何纠纷或争议，首先应友好协商解决。协商不成的，<strong><u>有关方应当将纠纷或争议提交至佛山南海铝拍拍科技有限公司企业注册所在地的人民法院通过诉讼的方式解决。</u></strong></p>
            <p class="article-title"><strong>3</strong><strong>2</strong><strong>.【规则的生效与变更】</strong></p>
            <p>本规则于2021年&nbsp;&nbsp;月&nbsp;&nbsp;日公示，公示期为7日，公示期满后于2021年&nbsp;&nbsp;月&nbsp;&nbsp;日实施生效。在符合《电子商务法》或其他适用法律规定的公示要求或其他强制性要求的前提下，铝拍拍方有权根据需要不时地重述、修改本协议，并以在铝拍拍平台公告的方式通知买方。<strong><u>如不同意相关变更的，用户必须立即停止任何使用铝拍拍平台的行为。用户注册和/或使用铝拍拍平台的行为即构成用户对公告及所涉相关规则变更（无论该等规则是否以弹窗形式单独要求买家确认）的无条件确认与接受。</u></strong>变更生效后的本规则对该等规则变更生效前的各方发生的行为或该等行为产生的法律后果均不具有溯及力。</p>
            <p>&nbsp;</p>
        </div>
        <div style="text-align:center;padding-bottom:20px">
          <el-button type="primary"
                    @click="approval(true)">确认并且接受</el-button>
          <el-button @click="approval(false)">取消</el-button>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  methods: {
    approval (isAgree) {
      this.$router.back();
      sessionStorage.setItem('isAgreeUserNotice', isAgree);
    }
  }
}
</script>

<style lang="less" scoped>
@import '../Doc/components/style.less';
.doc-container {
  margin: 30px auto;
  width: 1200px;
  box-sizing: border-box;

  .content {
    width: 1000px;
    margin: 0 auto;
  }
}
.wrap {
  margin: 30px auto;
  width: 1200px;
  text-align: justify;
  background: #fff;
  overflow: hidden;
  text-indent: 2em;
  box-sizing: border-box;

  h1 {
    text-align: center;
    font-size: 30px;
    margin: 20px 0;
  }

  p {
    margin-bottom: 20px;
    font-size: 12px;
    .title {
      font-weight: bold;
      margin-right: 1em;
    }
  }

  .prompt {
    color: red;
    font-size: 16px;
  }
}

.paragraph {
  margin-bottom: 10px;
  text-indent: 2em;
}

.subtitle {
  margin-bottom: 10px;
}
</style>
